'use strict';

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());

    if ($(selector).siblings('.filter-bar-line').is(':visible')) {
        $(selector).siblings('.filter-bar-line').hide();
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });

    // Update Apply Button
    $('.apply-button').html('View ' + $('.grid-header .result-count span').text());

    // Update Product List Count
    $('.result-count span').html($('.grid-header .result-count span').text());

    // Hide Grid Header that shows
    $('.grid-header .result-count').hide();

    // Update Filter Button
    if ($('.filter-active').length === 0) {
        var filterResultsText = $('.btn.filter-results').attr('data-filter-text');
        $('.btn.filter-results').text(filterResultsText);
    } else {
        var hideResultsText = $('.btn.filter-results').attr('data-hide-text');
        $('.btn.filter-results').text(hideResultsText);
    }
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getCount($element, $target) {
    var showMoreUrl = $element.data('url');
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            var contentSearchResult = $(response).find('.row');
            if ($target && $target.length && contentSearchResult.length) {
                if (contentSearchResult.hasClass('count-no-results')) {
                    $target.append('(0)');
                } else {
                    $target.append('(' + contentSearchResult.length + ')');
                }
            }
        },
        error: function () {
        }
    });
}

/**
 * Save the content components to the window object, so they can be added back to the
 * PLP after the sort or filter event
 */
function preparePlpGridContentForAjax() {
    if (window.plpGridContent && window.plpGridContentCount) {
        return;
    }
    var plpGridContent = [];
    var plpGridContentCount = 0;
    $('#product-search-results .region,.grid-tile-wrapper').each(function (index) {
        var $this = $(this);
        if ($this.hasClass('plp-grid-content')) {
            // banner counts for 2 spaces
            var size = 1;
            if ($this.hasClass('plp-grid-banner')) {
                plpGridContentCount += 2;
                size = 2;
            } else {
                plpGridContentCount += 1;
            }
            plpGridContent.push({
                index: index,
                element: $this,
                size: size
            });
        }
    });
    window.plpGridContent = plpGridContent;
    window.plpGridContentCount = plpGridContentCount;
}

/**
 * We're deleting some product tiles from the page, so when we request more products from the server with
 * the "load more" button, we need to tell the server to start at an index that is offset by the number of
 * products we removed from the page
 * @param {number} removedProductTileCount The number of product tiles we need to remove from the page to
 * make space for the content tiles
 */
function modifyLoadMoreButton(removedProductTileCount) {
    try {
        // get data-url attribute value
        var url = $('#product-search-results .show-more button').data('url');
        // get start parameter from url
        if (typeof url === 'string') {
            if (url.includes('start=')) {
                var regex = /start=(.*)[$|&]/;
                var match = url.match(regex);
                var currentStart = Number(match[1]);
                // subtract the number of products removed from the start parameter
                var updatedStart = currentStart - removedProductTileCount;
                var originalUrlStartValue = match[0];
                var newUrlStartValue = originalUrlStartValue.includes('&') ? 'start=' + updatedStart + '&' : 'start=' + updatedStart;
                url = url.replace(originalUrlStartValue, newUrlStartValue);
                $('#product-search-results .show-more button').data('url', url);
            }
        }
    } catch (e) {
        console.error('Error modifying load more button', e);
    }
}

/**
 * The ShowAjax HTML adds a bunch of products to the page, we want to add some of the content back in,
 * removing some of the products but changing the "load more" button data so we don't miss any products when we load more
 * @param {Array} plpGridContent An array that contains JQuery html objects and their positions, to substitute onto the page
 * @param {number} plpGridContentCount The number of content tiles being repopulated on the page (header counts as 2)
 */
function handleRepopulatingPlpContent() {
    try {
        var plpGridContentCount = window.plpGridContentCount;
        if (typeof plpGridContentCount === 'undefined' || plpGridContentCount === 0) {
            return;
        }
        var plpGridContent = window.plpGridContent;
        var removedProductTileCount = 0;
        for (let i = 0; i < plpGridContent.length; i += 1) {
            const plpGridContentItem = plpGridContent[i];
            var numberOfProductResultsOnPage = $('#product-search-results .product-grid .grid-tile-wrapper').length;
            // if there are less products than the index of the content, then don't display
            if (plpGridContentItem.index > numberOfProductResultsOnPage) {
                break;
            }
            plpGridContentItem.element.insertBefore($('#product-search-results .product-grid .region,.grid-tile-wrapper')[plpGridContentItem.index]);
            // Only remove content if there is a "load more" button on the page indicating pagination
            if ($('#product-search-results .show-more').length) {
                // pop a product tile from the page for each "size" unit of the content (so banners pop 2)
                for (let j = 0; j < plpGridContentItem.size; j += 1) {
                    $('#product-search-results .product-grid .grid-tile-wrapper').last().remove();
                    removedProductTileCount += 1;
                }
            }
        }
        modifyLoadMoreButton(removedProductTileCount);
    } catch (error) {
        console.error('Error Repopulating PLP Content: ' + error);
    }
}
/**
    * Setting appropriate classes for the filter-bar drawer.
    * It should be open for large screens and closed for mobile and tablets.
*/
function adjustClasses() {
    if ($(window).width() > 1023) {
        $('.collapsed').removeClass('collapsed');
        $('.panel-collapse.collapse').addClass('show');
    } else {
        $('a[data-toggle="collapse"]').addClass('collapsed');
        $('.panel-collapse.collapse').removeClass('show');
        if ($('.selected').length > 0) {
            $('.selected').closest('.panel-collapse').addClass('show');
            $('.selected').closest('.panel-collapse').prev().find('.collapsed')
                .removeClass('collapsed');
        }
    }
}

adjustClasses();

module.exports = {
    filter: function () {
        // Display refinements bar when Menu icon clicked
        $('.container').on('click', 'button.filter-results', function () {
            if ($('body').hasClass('filter-active')) {
                $('.refinement-bar').siblings().attr('aria-hidden', false);
                $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
                $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
                $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
                var filterText = $(this).attr('data-filter-text');
                $('.btn.filter-results').text(filterText);
                $('.panel-collapse').removeClass('collapse');
            } else {
                $('.refinement-bar').siblings().attr('aria-hidden', true);
                $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
                $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
                $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
                var hideText = $(this).attr('data-hide-text');
                $('.btn.filter-results').text(hideText);
                $('.panel-collapse').addClass('collapse');
            }
        });
    },

    closeRefinements: function () {
        // Refinements close button
        $('.container').on('click', '.refinement-bar button.close, .modal-background, body', function () {
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
            var closeFilterText = $(this).attr('data-filter-text');
            $('.btn.filter-results').text(closeFilterText);
            $('.btn.filter-results').focus();
        });
    },

    resize: function () {
        // Close refinement bar and hide modal background if user resizes browser
        $(window).resize(function () {
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
            adjustClasses();
        });
    },

    sort: function () {
        // Handle sort order menu selection
        $('.container').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:sort', this.value);

            preparePlpGridContentForAjax();

            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    handleRepopulatingPlpContent();
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    showMore: function () {
        // Show more products
        $('.container').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            var showMoreUrl = $(this).data('url');
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    applyFilter: function () {
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button, .filter-bar button.reset',
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                // checking if current element has parent element 'e'
                $.fn.hasParent = function (el) {
                    return !!$(this).parents(el).length;
                };
                window.dataLayer = window.dataLayer || [];

                if ($(this).hasParent('.refinements')) {
                    // if the filter has already been selected, then don't push to dataLayer
                    if (!$(this).find('.selected').length) {
                        window.dataLayer.push({
                            event: 'product_filter_click',
                            filter_category: $(this).closest('.panel-collapse').prev().find('h2')
                                .text(),
                            filter_sub_category: $(this).find('span').first().text()
                                .trim(),
                            page_name: $('title').html(),
                            page_location: window.location.href,
                            hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
                        });
                    }
                }

                // store the elements that also have the class "plp-grid-content" and their index relative to other regions
                preparePlpGridContentForAjax();

                $.spinner().start();
                $(this).trigger('search:filter', e);
                $.ajax({
                    url: $(this).data('href'),
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: $(this).data('href')
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);
                        handleRepopulatingPlpContent();
                        $.spinner().stop();
                        adjustClasses();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        );
    },

    showContentTab: function () {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },

    showCount: function () {
        $(document).ready(function () {
            $('.search-nav .nav-link').each(function () {
                getCount($(this), $(this).find('.tab-count'));
            });
        });
    },

    clearFilter: function () {
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.filter-value button', function () {
                $('.filter-bar').siblings('button.reset').remove();
            }
        );
    }

};
